import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { Button, Card, Container, Row, Col, Form } from 'react-bootstrap';
import { Grid } from '../../components/grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form'
import '../../styles/clubDeViaje.css'
import { InputSelectHookForm, } from '../../components/Forms/InputsFormsReactHook';
import { ObtenerFrecuenciasDePago,} from '../../servicios/ServicioClubDeViaje';
import { ObtenerCobros } from '../../servicios/ServicioCobro';
import { AlertDismissible } from '../../components/alerts';
import '../../styles/Cobro.css'
import { InputText } from '../../components/inputs';


//import Calendar from 'react-calendar'

const Cobros = (proceso = 1, data) => {

    const [ListaCobros, setListaCobros] = useState([]);
    const [verDias, setverDias] = useState(false);
    const [verMes, setverMes] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [mensajeBuscar, setmensajeBuscar] = useState({ indicador: '0', mensaje: 'Primero selecione una opción de frecuencia de pago' });
    const [mensajeDescargar, setmensajeDescargar] = useState({ indicador: '0', mensaje: 'No tiene información para descargar' });

    const [fechaPago, setFechaPago] = useState('');


    const encabezadoCobros = [
        { id: 'id', name: 'id', selector: row => row.id, head: "id", omit: true },
        { id: 'socioRebajo', name: 'Socio Rebajo', selector: row => row.socioRebajo, head: "SocioRebajo", sortable: true, width: '15%' },
        { id: 'tipoDescargo', name: 'Tipo Descargo', selector: row => row.tipoDescargo, head: "TipoDescargo", sortable: true, width: '18%' },
        { id: 'Numero', name: 'Numero', selector: row => row.numero, head: "Número", sortable: true, width: '15%' },
        {
            id: 'FecVencimiento', name: 'Fecha de Vencimiento', selector: row => row.nFecVencimiento,
            head: "Fecha de Vencimiento", sortable: true, width: '20%' 
        },
        { id: 'Monto', name: 'Monto', selector: row => row.monto, head: "Monto", sortable: true },
        {
            id: 'FechaPago', name: 'Fecha de Descargo', selector: row => row.fechaPago,
            head: "Fecha de Pago", sortable: true, width: '14%', omit: true 
        },
        {
            id: 'correoElectronico', name: 'Correo Electronico', selector: row => row.correoElectronico,
            head: "Correo Electrónico", sortable: true, width: '20%' 
        },
        { id: 'nombre', name: 'Nombre Socio', selector: row => row.nombre, head: "Nombre", sortable: true, width: '20%' },
    ];

    const generarArchivoExcel = () => {
        //Si la ListaCobros esta vacio significa que existe informacion o no se ha consultado
        if (ListaCobros.length === 0 || ListaCobros === null) {
            setMensajeRespuesta(mensajeDescargar);
            setShowAlert(true)
        } else {



            var fechaSeleccionada = fechaActual();

            const listaFormateadaCSV = ListaCobros.map(
                item => ({
                    id: item.id,
                    socioRebajo: item.socioRebajo,
                    tipoDescargo: item.tipoDescargo,
                    numero: item.numero,
                    nFecVencimiento: item.nFecVencimiento.replace('/', ''),
                    monto: item.monto,
                    fechaDescargo: fechaSeleccionada,
                    correoElectronico: item.correoElectronico,
                    nombre: item.nombre
                })
            );

            const listaFormateadaXLSX = ListaCobros.map(
                item => ({
                    id: item.id,
                    socioRebajo: item.socioRebajo,
                    tipoDescargo: item.tipoDescargo,
                    numero: item.numero,
                    nFecVencimiento: item.nFecVencimiento.replace('/', ''),
                    monto: item.monto,
                    fechaDescargo: fechaSeleccionada,
                    correoElectronico: item.correoElectronico,
                    nombre: item.nombre,
                    estado:''
                })
            );

            const listaCSV2 = listaFormateadaCSV.map(item => { return { ...item, id: 1 } });

            //const sinHeaders = XLSX.utils.json_to_sheet(listaFormateada, { skipHeader: true });
            const conHeaders = XLSX.utils.json_to_sheet(listaFormateadaXLSX);

            // Crear un libro de Excel y agregar la hoja de cálculo, para guardar como XLSX
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, conHeaders, 'Hoja1');
            XLSX.writeFile(wb, `Rebajos ${fechaSeleccionada}.xlsx`);

            // Convierte los datos a CSV manualmente
            const listaCSV = listaCSV2.map(item => Object.values(item).join(",")).join("\n");

            const a = window.document.createElement('a');
            a.setAttribute('href', 'data:text/csv; charset=utf-8-sig,' + encodeURIComponent(listaCSV));
            a.setAttribute('download', `Rebajos ${fechaSeleccionada}.csv`);
 
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
        }
        
    };

    function fechaActual() {

        var partes = fechaPago.split('-');

        var annio = partes[0];
        var mes = partes[1];
        var dia = partes[2];

        let formattedDate = `${dia}${mes}${annio}`;

        return fechaPago ? formattedDate : new Date();
    };

    const [showDownloadButton, setShowDownloadButton] = useState(false);

    useEffect(() => {
        // Verificar si hay resultados en ListaCobros y actualizar el estado showDownloadButton
        setShowDownloadButton(ListaCobros.length > 0 && ListaCobros !== null);
    }, [ListaCobros]);


    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [listaFrecuenciaDePago, setListaFrecuenciaDePago] = useState([]);

    useEffect(() => {
        ObtenerListadoDatosSelects();
        obtenerDatosCobros();
    }, []);

    const ObtenerListadoDatosSelects = async () => {
        const frecuenciaDePago = await ObtenerFrecuenciasDePago();
        if (frecuenciaDePago !== undefined) {
            setListaFrecuenciaDePago(frecuenciaDePago);
        }
    }

    //Metodo para obtener cobro mandando a consultar 
    const obtenerDatosCobros = async () => {
        setListaCobros([]);
        const frecuencia = frecuenciaDePago ? frecuenciaDePago : 0;

        //PARA LA PRIMERA CARGA DE LA PÁGINA, LA FECHA SOLO ESTÁ EN EL ELEMENTO
        const fechaCobros = fechaPago ? fechaPago : fechaActual();

        var data = {
            FrecuenciaDePago: frecuencia,
            FechaCobro: fechaCobros
        }

        const resultado = await ObtenerCobros(data);


        var resultadoConFechasFormateadas = resultado.map(item => (
            { ...item, nFecVencimiento: formatearFechaVencimiento(item.nFecVencimiento) }
        ));

        LlenaTabla(resultadoConFechasFormateadas);
    };

    function formatearFechaVencimiento(fecha) {
        var partes = fecha.split('/');

        var mes = partes[0];
        var annio = partes[1];

        var fechaConDia = mes + "/01/" + annio; // El formato que recibe Date es mm/dd/yy, por eso hay que construirlo

        var fechaCompleta = new Date(fechaConDia);

        annio = fechaCompleta.getFullYear(); // Para obtener el año, pero completo. Ej: 23 -> 2023

        return `${mes}/${annio}`;
    }

    const LlenaTabla = (resultado) => {

        setListaCobros(resultado);

        //if (frecuenciaDePago === "1") {
        //    setListaCobros([]);
        //    for (let i = 0; i < resultado.length; i++) {
        //        const item = resultado[i]; // Obtenemos el objeto actual
        //        if (selectedDays.includes(item.fechaPago)) {
        //            // Comparamos la propiedad fechaPago con selectedDays
        //            setListaCobros((prevListaCobros) => [...prevListaCobros, item]);
        //        }
        //    }
        //}
        //if (frecuenciaDePago === "2" || frecuenciaDePago === "3") {
        //    setListaCobros([]);
        //    for (let i = 0; i < resultado.length; i++) {
        //        const item = resultado[i]; // Obtenemos el objeto actual
        //        if (selectedMes.includes(item.fechaPago)) {
        //            // Comparamos la propiedad fechaPago con selectedDays
        //            setListaCobros((prevListaCobros) => [...prevListaCobros, item]);
        //        }
        //    }
        //}
    };


    const { register: register, handleSubmit: handleSubmit, formState: { errors: errors }, trigger: trigger } = useForm();
    const [isSummit, setIsSummit] = useState(false);
    const [isSummitInfo, setIsSummitInfo] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };


    const [personaSeleccionada, setPersonaSeleccionada] = useState({});
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
  

    useEffect(() => { //ASIGNAR LA FECHA DEL DÍA
        const date = new Date(Date.now());
        const formattedDate = date.toJSON().slice(0, 10);
        setFechaPago(formattedDate);
    }, []);




    const handleManualValidationInformacionGeneral = async () => {
        const isValid = await trigger();
        if (!isValid) {
            setIsSummitInfo(true)
        }
    };


    const handleSubmitInfoGeneral = async (data) => {
        if (personaSeleccionada.idPersona === null || personaSeleccionada.idPersona === undefined) {
            setConfirmModalOpen(true)
        }
        else {

        }
    };


    const [frecuenciaDePago, setfrecuenciaDePago] = useState(proceso == 2 ? data.IdTipoCuenta : 0);
    const onChangeFrecuenciaDePago = async (event) => {
        setfrecuenciaDePago(event.target.value);
        //if (event.target.value == "1") {
        //    setverDias(true);
        //    setverMes(false);
        //} else if (event.target.value == "2" || event.target.value == "3") {
        //    setverDias(false);
        //    setverMes(true);
        //}
    }

    //Logica para los dias de la semana
    const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
    const [selectedDays, setSelectedDays] = useState([]);

    const areInputsEmpty = () => {
        return selectedDays.length === 0;
    };


    const handleSelectAll = () => {
        if (selectedDays.length === daysOfWeek.length) {
            setSelectedDays([]);
        } else {
            setSelectedDays([...daysOfWeek]);
        }
    };

    const handleDaySelect = (day) => {
        if (selectedDays.includes(day)) {
            setSelectedDays(selectedDays.filter((selectedDay) => selectedDay !== day));

        } else {
            setSelectedDays([...selectedDays, day]);
        }
        // Actualiza el estilo del checkbox
        const checkbox = document.querySelector(`input[value="${day}"]`);
        if (checkbox) {
            if (selectedDays.includes(day)) {
                checkbox.style.backgroundColor = 'transparent'; // Cambia el fondo a transparente cuando se deselecciona
            } else {
                checkbox.style.backgroundColor = '#0069d9'; // Cambia el fondo al color deseado cuando se selecciona
            }
        }
    };

    //Logica para quincenal y mensual
    const [selectedMes, setselectedMes] = useState([]);
    // Generar opciones del 1 al 31
    const options = [];
    for (let i = 1; i <= 31; i++) {
        options.push(i);
    }

    const handleSelectChange = (e) => {
        const value = e.target.value;
        setselectedMes(value);
        
    };



    const handleSearch = async () => {
        if (!frecuenciaDePago) {
            alert('Debes seleccionar un método de búsqueda.');
        } else if (frecuenciaDePago === "1" && selectedDays.length === 0) {
            alert('Debes seleccionar al menos un día cuando la frecuencia de pago es diaria.');
        } else if ((frecuenciaDePago === "2" || frecuenciaDePago === "3") && selectedMes.length === 0) {
            alert('Debes seleccionar al menos un día cuando la frecuencia de pago es quincenal o mensual.');
        } else {
            await obtenerDatosCobros();
        }
    };

    const onChangeFechaPago = (e) => {
        setFechaPago(e.target.value);
    }

    return (
        <>
            {showAlert && (
                <AlertDismissible
                    indicador={mensajeRespuesta.indicador}
                    mensaje={mensajeRespuesta.mensaje}
                    setShow={setShowAlert}
                />
            )}
            <Form onSubmit={handleSubmit(handleSubmitInfoGeneral)}>
                <div className='d-flex justify-content-around'>


                    <Card className="card-container" style={{ width: '90%' }}>
                        <Card.Body>
                            <Card.Title className="card-title mb-5"> Rebajos de Club de Viajes</Card.Title>

                            <div className='d-flex justify-content-end'>
                                
                            </div>

                            <Container className='d-Grid align-content-center'>

                                <Row style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }} className="mt-4 ">
                                    <Col md={3}>
                                        <InputSelectHookForm className="form-control custom-select"
                                            controlId="sel-FrecuenciaDePAgo"
                                            label={<strong>Frecuencia de Pago: </strong>}
                                            data={listaFrecuenciaDePago}
                                            onChange={onChangeFrecuenciaDePago}
                                            placeholder={"Todas"}
                                            value={frecuenciaDePago}
                                            mensajeValidacion={"Seleccione un Frecuencia de Pago"}
                                            register={{ ...register('IdFrecuenciaPago', { required: true }) }}
                                            optionValue="id"
                                            errors={errors?.IdFrecuenciaPago?.type}
                                            isSummit={isSummit}
                                            optionLabel="dscFrecuenciasDePago"
                                            classGroup="col-md-12"
                                        />

                                    </Col>
                                    <Col md={3}>
                                        <InputText id='txt-fechaPago' label={<strong>Fecha de Descargo: </strong>}
                                            type='date' placeholder='Seleccione la fecha' value={fechaPago}
                                            onChange={onChangeFechaPago} mensajeValidacion="La fecha es requerida"/>
                                    </Col>
                                    <Col md={3} class="d-flex align-items-center justify-content-center">
                                    <br></br>
                                        <Button
                                            variant="primary"
                                            className="mr-10px card-button"
                                            style={{
                                                margin: '2px',
                                                fontSize: '15px',
                                            }}
                                            onClick={obtenerDatosCobros}
                                        >
                                            <FontAwesomeIcon icon={faMagnifyingGlass} className="mr-2" />
                                            Buscar
                                        </Button>
                                    </Col>
                                    <Col md={9}>
                                        {verDias && (<div>
                                            <div style={{ textAlign: 'center' }}>
                                                {/*<button onClick={handleSelectAll} className="form-control" style={{ width: '13rem', marginBottom: '10px' }}>*/}
                                                {/*    Seleccionar Todos*/}
                                                {/*</button>*/}
                                            </div>
                                            <div >
                                            <div className="text-start">
                                                <strong>Seleccionar día de la semana:</strong>
                                            </div>
                                            <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <label
                                                        style={{
                                                            marginRight: '10px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <input
                                                            className="form-control"
                                                            type="checkbox"
                                                            value="Todos"
                                                            checked={selectedDays.length === daysOfWeek.length}
                                                            onChange={() => handleSelectAll()}
                                                            style={{
                                                                appearance: 'none',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '50%',
                                                                border: '1px solid #000',
                                                                cursor: 'pointer',
                                                                fontSize: '3px',
                                                                marginRight: '5px',
                                                                background: selectedDays.length === daysOfWeek.length ? '#0069d9' : 'transparent',
                                                            }}
                                                        />
                                                        Todos
                                                    </label>

                                                    {daysOfWeek.map((day, index) => (
                                                        <label
                                                            key={index}
                                                            style={{
                                                                marginRight: '10px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <input
                                                                className="form-control"
                                                                type="checkbox"
                                                                value={day}
                                                                checked={selectedDays.includes(day)}
                                                                onChange={() => handleDaySelect(day)}
                                                                style={{
                                                                    appearance: 'none',
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    borderRadius: '50%',
                                                                    border: '1px solid #000',
                                                                    cursor: 'pointer',
                                                                    fontSize: '3px',
                                                                    marginRight: '5px',
                                                                }}
                                                            />
                                                            {day}
                                                        </label>
                                                    ))}
                                            </div>
                                            </div>

                                        </div>)}
                                        {verMes && (
                                            <div>


                                                <label htmlFor="daySelect">
                                                    <strong>Selecciona un día:</strong>
                                                </label>
                                                <select id="daySelect" className="form-control" onChange={handleSelectChange} value={selectedMes} style={{
                                                    width: '24.5%',
                                                }}>
                                                    <option value="selectAll">Seleccionar un día</option>
                                                    {options.map((day) => (
                                                        <option key={day} value={String(day)}>
                                                            {day}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                    </Col>

                                    <Col md={9} style={{
                                        margin: '20px',
                                        position: 'relative',
                                        right: '84px'
                                        
                                    }}>
                                    
                                    </Col>


                                    <div className="text-center">
                                        <strong className="card-title mb-5">Información Obtenida</strong>
                                    </div>
                                   

                                    <Container className='d-Grid align-content-center'>
                                        <Row className='d-flex mt-2'>
                                            
                                            <Col md={12} className="ml-auto"> {/* Agrega la clase ml-auto al Col que contiene el botón */}
                                                <Button
                                                    variant="Dark"
                                                    style={{
                                                        display: showDownloadButton ? 'inline-block' : 'none',
                                                        textAlign: 'center',
                                                        textDecoration: 'none',
                                                        cursor: 'pointer',
                                                        border: '2px solid #d85c2c',
                                                        color: '#d85c2c',
                                                        backgroundColor: 'white',
                                                        borderRadius: '20px',
                                                        fontSize: '15px',
                                                    }}
                                                    onClick={generarArchivoExcel}
                                                >
                                                    <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
                                                    Descargar
                                                </Button>
                                            </Col>
                                        </Row>

                                        <Row className="mt-4">
                                            <Col md={12}>
                                                    <Grid
                                                        gridHeading={encabezadoCobros}
                                                        gridData={ListaCobros}
                                                        /*selectableRows={false}*/
                                                        idBuscar="idPersona"
                                                    />
                                            </Col>                                           
                                        </Row>
                                    </Container>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </div>
            </Form>

        </>
    )
}

export default Cobros;